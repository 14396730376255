@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

.header {
    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
    height: 3rem;
    box-shadow: 2px 1px 1px rgb(25, 25, 25);
    background-color: black;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
}
.logo {
    position: fixed;
    z-index: 15;
    left: 2vw;
    display: inline-flex;
    align-items: center;
    
}
.reddit-logo {
    max-height: 2rem;
    margin-right: .75rem;
}
.reddit-logo-typed {
    font-family: 'Varela Round', sans-serif;
    color: white;
    margin-bottom: 1.5rem;
}
input {
    border: 1px solid rgb(161, 161, 161);
    border-radius: 5px;
    height: 1.5rem;
    width: 20vw;
    background-color:rgb(215,215,215);
}
.input-box {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}
.search-icon {
    max-height: 1rem;
    cursor: pointer;
}
.reddit-logo:hover {
    border-radius: 100%;
    box-shadow: 2px 1px 1px rgb(250, 121, 0);
}
.sub-menu-link {
    color: white;
    cursor: pointer;
    position: fixed;
    right: 8vw;
    z-index: 15;
}
.mobile-sub-menu-link {
    display: none;
}
.loader {
    max-width: 100%;
    max-height: 100px;
    margin-top: 10rem;
}
@media (prefers-reduced-motion: no-preference) {
    .loader {
      animation: loading-spin infinite 1s linear;
    }
  }

  @keyframes loading-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

@media screen and (max-width: 1100px) {
    .reddit-logo-typed {
      display: none;
    }
    input {
        width: 30vw;
    }
    .sub-menu-link {
        display: none;
    }
    .mobile-sub-menu-link {
        cursor: pointer;
        position: fixed;
        display: inline;
        right: 3vw;
        z-index: 15;
        margin-top: 0;
        max-height: 1.2rem;
    }
    input, select, textarea {
        font-size: 16px;
    }
    .loader {
        max-width: 100%;
        max-height: 75px;
        margin-top: 3rem;
    }
  }
