.sub-menu-link {
  cursor: pointer;
  position: fixed;
  right: 8vw;
  z-index: 15;
}
.sub-link {
  cursor: pointer;
}
.clickable {
  cursor: pointer;
}
.sub-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 0;
  color: white;
  background-color: black;
  z-index: 30;
  box-shadow: -1px 0px 3px rgb(55, 55, 55);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: 0.5s;
  justify-content: space-around;
}
.close-button {
  font-size: 2rem;
  margin-bottom: 5vh;
}
.sub-link-box {
  padding: 0.5rem 0.5rem;
}
.sub-link-box:hover {
  background-color: rgb(78, 78, 78);
}
@media screen and (max-width: 1100px) {
  .sub-menu-link {
    right: 2vw;
  }
  .close-button {
    margin-bottom: 7vh;
  }
  .first-link {
    margin-top: 7vh;
  }
}
