@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  text-align: center;
  width: 100%;
  background-color: rgb(19, 19, 19);
  box-sizing: border-box;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  
}
html {
  scroll-behavior: smooth;
}
/*
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
.results {
    width: 60%;
    height: auto;
    margin: 2em auto;
    box-shadow: 2px 1px 1px rgb(50, 50, 50);
    background-color: black;
    color: white;
    border-radius: 4px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    border-left: 45px solid rgb(50, 50, 50);
    box-sizing: border-box;
}
.results:hover{
    box-shadow: 2px 1px 1px rgb(55, 55, 55);
    border-left: 45px solid rgb(55, 55, 55);
}
.results-box {
    padding-top: .001rem;
    padding-left: 1rem;
    text-align: left;
}
.media-box {
    margin: 1em auto;
    -webkit-align-content: center;
            align-content: center;
    padding: 1rem;
}
.media-box img {
    max-width: 100%;
    max-height: 70vh;
    margin-bottom: 1.5rem;
}
.media-box video {
    max-width: 100%;
    max-height: 70vh;
    margin-bottom: 1.5rem;
}
.gifs {
    width: 100;
    height: 100;
}
.results-box embed {
    max-width: 100%;
    max-height: 50vh;
}
a {
    text-decoration: none;
    color: white;
}
.post-heading {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    margin-top: .5rem;
}
.sub-icon {
    max-width: 2rem;
    max-height: 2rem;
}
.sub-name {
    margin-top: .8rem;
    padding-right: 1rem;
    padding-left: .8rem;
    cursor: pointer;
}
.author {
    margin-top: .8rem;
    font-size: .7rem;
    color: gray;
    
}
.post-title {
    padding-right: 1rem;
    
}
.votes {
    position: absolute;
    font-size: .6rem;
    margin-left: -53px;
    margin-top: 1rem;
    font-weight: 800;
}

.comments {
    padding-left: .8rem;
    cursor: pointer;
}
.comments:hover {
    color: white;
}
.percent-upvote {
    padding-right: 1.7rem;
}
.comments-and-percent {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
    font-size: .7rem;
    color: gray;
    margin-top: -3rem;
    margin-bottom: -3rem;
}
.hide-comments {
    display: none;
}
.read-more {
    cursor: pointer;
    font-size: .8rem;
    text-decoration: underline;
    margin-top: -.4rem;
}
.show-selftext {
    display: block;
}
.hide-selftext {
    display: none;
}
.mobile-votes {
    display: none;
}
@media screen and (max-width: 1100px) {
    .results {
        width: 100%;
        border-left: none;
        box-shadow: none;
    }
    
    .mobile-votes {
        display: block;
        padding-left: 1.7rem;
    }
    .votes {
        display: none;
    }
    .post-heading {
        display:-webkit-inline-flex;
        display:inline-flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        margin-bottom: .2rem;
        padding-top: 1rem;
    }
    .author {
        padding-left: .8rem;
    }
    .post-heading p {
        line-height: 0rem;
    }
    .results:hover{
        border-left:none;
    }
}
@media screen and (max-width: 480px) {
    .results img {
        border-radius: 0px;
        box-shadow: none;
    }
    .media-box {
        padding: 0;
    }
    .results {
        border-radius: 0px;
        box-shadow: none;
    }
    .results:hover{
        box-shadow: none;
        border-left: none;
    }
    .post-heading {
        margin-top: -1rem;
    }
    .author {
        margin-top: -.5rem;
        margin-left: 2rem;
    }
    .sub-icon {
        margin-top: 1rem;
    }
    .media-box {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }
    .comments-and-percent {
        margin-bottom: -1rem;
    }
}
.comments {
    display: block;
    text-align: left;
    margin-bottom: 2rem;
}
.comments-header {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: baseline;
            align-items: baseline;
}
.comment-posted {
    margin-left: 1rem;
    color: gray;
    font-size: .7rem;
}
.comment-author {
    color: white;
    font-size: 1rem;
}
.comment-body {
    margin-top: -.2rem;
}
.comment-votes {
    font-size: .6rem;
    font-weight: 800;
}
input {
    margin: 1rem;
}
.searchResults {
    margin-top: 4rem;
}
.loading {
    margin-top: 10rem;
    max-height: 100px;
}
.empty-image {
    border-radius: 25px;
}

@media (prefers-reduced-motion: no-preference) {
    .loading {
      -webkit-animation: loading-spin infinite 1s linear;
              animation: loading-spin infinite 1s linear;
    }
  }

  @-webkit-keyframes loading-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  @keyframes loading-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
.sub-menu-link {
  cursor: pointer;
  position: fixed;
  right: 8vw;
  z-index: 15;
}
.sub-link {
  cursor: pointer;
}
.clickable {
  cursor: pointer;
}
.sub-menu {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 0;
  color: white;
  background-color: black;
  z-index: 30;
  box-shadow: -1px 0px 3px rgb(55, 55, 55);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: 0.5s;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}
.close-button {
  font-size: 2rem;
  margin-bottom: 5vh;
}
.sub-link-box {
  padding: 0.5rem 0.5rem;
}
.sub-link-box:hover {
  background-color: rgb(78, 78, 78);
}
@media screen and (max-width: 1100px) {
  .sub-menu-link {
    right: 2vw;
  }
  .close-button {
    margin-bottom: 7vh;
  }
  .first-link {
    margin-top: 7vh;
  }
}

.header {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
    height: 3rem;
    box-shadow: 2px 1px 1px rgb(25, 25, 25);
    background-color: black;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
}
.logo {
    position: fixed;
    z-index: 15;
    left: 2vw;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    
}
.reddit-logo {
    max-height: 2rem;
    margin-right: .75rem;
}
.reddit-logo-typed {
    font-family: 'Varela Round', sans-serif;
    color: white;
    margin-bottom: 1.5rem;
}
input {
    border: 1px solid rgb(161, 161, 161);
    border-radius: 5px;
    height: 1.5rem;
    width: 20vw;
    background-color:rgb(215,215,215);
}
.input-box {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
}
.search-icon {
    max-height: 1rem;
    cursor: pointer;
}
.reddit-logo:hover {
    border-radius: 100%;
    box-shadow: 2px 1px 1px rgb(250, 121, 0);
}
.sub-menu-link {
    color: white;
    cursor: pointer;
    position: fixed;
    right: 8vw;
    z-index: 15;
}
.mobile-sub-menu-link {
    display: none;
}
.loader {
    max-width: 100%;
    max-height: 100px;
    margin-top: 10rem;
}
@media (prefers-reduced-motion: no-preference) {
    .loader {
      -webkit-animation: loading-spin infinite 1s linear;
              animation: loading-spin infinite 1s linear;
    }
  }

  @-webkit-keyframes loading-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  @keyframes loading-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

@media screen and (max-width: 1100px) {
    .reddit-logo-typed {
      display: none;
    }
    input {
        width: 30vw;
    }
    .sub-menu-link {
        display: none;
    }
    .mobile-sub-menu-link {
        cursor: pointer;
        position: fixed;
        display: inline;
        right: 3vw;
        z-index: 15;
        margin-top: 0;
        max-height: 1.2rem;
    }
    input, select, textarea {
        font-size: 16px;
    }
    .loader {
        max-width: 100%;
        max-height: 75px;
        margin-top: 3rem;
    }
  }

